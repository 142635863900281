import React from "react";
import { RawOverpassNode } from "./interfaces";

interface Props {
  item: RawOverpassNode;
}
function CommonDetails(props: { item: RawOverpassNode }) {
  const { item } = props;

  return (
    <div>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.openstreetmap.org/node/${item.id}`}
        >
          Additional details (OSM node {item.id})
        </a>
      </p>
    </div>
  );
}

export default function Card(props: Props) {
  const { item } = props;
  return (
    <div>
      <div
        style={{
          display: "flex",
          margin: "8px 0",
          flexDirection: "column",
        }}
      >

        <p style={{ marginLeft: 4 }}>{JSON.stringify(item, null, 2)}</p>
        <CommonDetails item={item}></CommonDetails>
        {/* <p style={{marginLeft: 4}}>{item.Fulldescription}</p> */}
      </div>
    </div>
  );
}
